import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import {
  Button,
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
  RenderPageProps,
} from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const AWS = require("aws-sdk");

AWS.config.update({
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({ apiVersion: "2006-03-01" });

export default function ConceptMapPdfView(props) {

  const history = useHistory();
  const { state } = useLocation();

  const [notes, setNotes] = useState([]);
  const [pdfUrl, setPdfUrl] = useState();
  const [showFirstPageView, setShowFirstPageView] = useState(0);

  useEffect(() => {
    if (state?.pdf !== '' && state?.pdf !== undefined) {
      localStorage.setItem('storeDamPdf', state?.pdf);
    }
    let lastPdfFile = localStorage.getItem('storeDamPdf');
    

    // getPdfContentFromAWSS3BucketPrivately(state?.pdf??lastPdfFile);
    getPdfContentFromAWSS3BucketPrivately(state?.pdf !== '' && state?.pdf !== undefined && state?.pdf !== null ?  state?.pdf  : lastPdfFile);
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    console.log("state....", state);
    caches.delete(pdfUrl);
  }, [pdfUrl]);

  /* open pdf link */
  const openLinksPlugin = () => {
    const findLinks = (e) => {
      e.container
        .querySelectorAll("a:not([data-annotation-link-dest])")
        .forEach((link) => {
          let href = link.getAttribute("href");
          if (href && href != "") {
            link.addEventListener("click", handleClick);
          }
        });
    };

    /* create link */
    const handleClick = (e) => {
      e.preventDefault();
      let href = e.target.href;
      let domainName = process.env.REACT_APP_ADMIN_PDF_URL;
      let midPath = "elibrary-content-pdf-view";
      if (href.indexOf("http") >= 0) {
        let parts = href.split("/");
        href = parts[parts.length - 1];
      }
      let finalURL = domainName + midPath + "/" + href;
      window.open(finalURL, "_self", "noreferrer");
    };

    return {
      onAnnotationLayerRender: findLinks,
    };
  };

  const openLinksPluginInstance = openLinksPlugin();

  /* find s3 pdf find name */
  const getPdfContentFromAWSS3BucketPrivately = (url) => {
    setPdfUrl();
    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
    //"https://crestestclv.s3.ap-south-1.amazonaws.com/elibrary/1733466564163.pdf"
    let parts = url.split("/");
    const pdfFileName = parts[parts.length - 1];
    let pdfFileWithoutPageNumber = pdfFileName.split("#");
    let finalPdfFileWithoutPageNumber = pdfFileWithoutPageNumber[0].trim();

    let fullPathPdf = `elibrary/${finalPdfFileWithoutPageNumber}`;

    s3.getObject(
      {
        Bucket: S3_BUCKET_NAME,
        Key: fullPathPdf,
        ResponseContentType: "Uint8Array",
      },
      (err, data) => {
        if (err) {
          console.log("@@Error : " + err);
        } else {
          console.log('data...', data);
          setPdfUrl(data.Body);
        }
      }
    );
  };

  /* pdf plugin */
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  let noteId = notes.length;

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={highlightContent}>
              <MessageIcon />
            </Button>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4,
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights,
  });

  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: "none" }}>{props.textLayer.children}</div>
        {props.annotationLayer.children}
      </>
    );
  };

  /* navigtion go back function */
  const onGoBackHandler = () => {
    props.history.replace({
      pathname: "/designer-request-details",
      state: {
        ...state,
      },
    });
  };

  return (
    <div className="App">
      <div className="col-xl-2">
        <div className="back-arrow-container">
          <button onClick={onGoBackHandler}>
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </div>
      </div>
      {pdfUrl != undefined && pdfUrl != "" ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <div
            style={{
              height: "700px",
              border: "1px solid rgba(250, 0, 250, 1)",
            }}
          >
            <Viewer
              initialPage={showFirstPageView}
              fileUrl={pdfUrl}
              renderPage={renderPage}
              plugins={[
                openLinksPluginInstance,
                highlightPluginInstance,
                zoomPluginInstance,
              ]}
            />
          </div>
        </Worker>
      ) : null}
    </div>
  );
}
