import {
    formatError,
    login,
    runLogoutTimer,
    changePassword,
    saveTokenInLocalStorage,
    signUp,
    checkToken,
    updateProfile,
    forgotPassword,
    resetPassword,
    getNotificationDetailsDetails,
    getReadNotificationDetails
} from '../../services/AuthService';

import {
    LOGIN_CONFIRMED,
    LOADING_TOGGLE,
    LOGIN_FAILED,
    LOGOUT,
    UPDATE_PROFILE,
    SIGNUP_CONFIRMED,
    SIGNUP_FAILED,
    NOTIFICATION_DETAILS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function signupAction(fullname, email, usertype, phone, password, history) {
    return (dispatch) => {
        signUp(fullname, email, usertype, phone, password)
            .then((response) => {
                dispatch(confirmedSignupAction(false));
                utility.showSuccess(response.data.msg);
                history.push('/login');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data.status === 200) {
                    saveTokenInLocalStorage(response.data.data[0]);
                    dispatch(loginConfirmedAction(response.data.data[0]));
                    utility.showSuccess(response.data.msg);
                    history.push('/dashboard');
                } else {
                    utility.showError(response.data.msg);
                    dispatch(loadingToggleAction(false));
                }
            })
            .catch((error) => {
                dispatch(loadingToggleAction(false));
            });
    };
}

export function checkTokenAction(tokenDetails, history) {
    return (dispatch) => {
        checkToken()
            .then((response) => {
                if (response.data.status === 410) {
                    dispatch(logout(history));
                } else {
                    dispatch(loginConfirmedAction(tokenDetails));
                }
            })
            .catch((error) => {
                dispatch(logout(history));
            });
    };
}

export function updateProfileData(name, email, phone, file, history) {
    return (dispatch) => {
        updateProfile(name, email, phone, file)
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(updateProfileAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function changePasswordData(old_password, new_password, history) {
    return (dispatch) => {
        changePassword(old_password, new_password)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(logout(history));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function forgotPasswordData(email, history) {
    return (dispatch) => {
        forgotPassword(email)
            .then((response) => {
                if (response.data.status == 200) {
                    history.goBack();
                    utility.showSuccess(response.data.msg);
                    dispatch(loadingToggleAction(false));

                } else {
                    utility.showError(response.data.msg);
                    dispatch(loadingToggleAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function resetPasswordAction(message, password, history) {
    return (dispatch) => {
      resetPassword(message, password)
        .then((response) => {
          dispatch(loadingToggleAction(false));
          utility.showSuccess(response.data.msg);
        })
        .catch((error) => {
          dispatch(loadingToggleAction(false));
        });
    };
  }


  export function getNotificationDetailsData(history) {
    return (dispatch) => {
        getNotificationDetailsDetails()
            .then((response) => {
                dispatch(getNotificationDetailsAction(response.data.data))
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getReadNotificationData(item) {
    return (dispatch) => {
        getReadNotificationDetails(item.id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getNotificationDetailsData()) /* assign-developer-edit */
                    // history.push({
                    //     pathname: '/assign-developer-edit',
                    //     state: item
                    // })
                    // console.log("%%%%---111", response)
                    utility.showSuccess(response.data.msg);
                    // dispatch(getNotificationDetailsAction(response.data.data))
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                // console.log(error);
                // utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED,
        payload: data,
    };
}

export function confirmedSignupAction(data) {
    return {
        type: SIGNUP_CONFIRMED,
        payload: data,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED,
        payload: message,
    };
}
export function updateProfileAction(data) {
    return {
        type: UPDATE_PROFILE,
        payload: data,
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE,
        payload: status,
    };
}

export function getNotificationDetailsAction(data) {
    return {
        type: NOTIFICATION_DETAILS,
        payload: data,
    };
}
