import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
// image
import logo from "../../images/logo.png";
import {
  resetPasswordAction, loadingToggleAction,
} from '../../store/actions/AuthActions';

const ResetPassword = (props) => {

  const dispatch = useDispatch();

  let errorsObj = { pws: '', cpws: '' };

  const [errors, setErrors] = useState(errorsObj);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // console.log(">>>>>>>")
    const userInfo = window.location.href.split('#');
    const message = userInfo[userInfo.length - 1];
    setMessage(message);

  }, [])


  /* form submit */
  const onSubmit = (e) => {

    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (password === '') {
      errorObj.pws = 'Password is Required';
      error = true;
    } else if (password.length < 6 || password.length > 10) {
      errorObj.pws = 'Password minimum 6 and maximum 10 characters Required';
      error = true;
    }

    if (confirmPassword === '') {
      errorObj.cpws = 'Confirm Password is Required';
      error = true;
    } else if (confirmPassword !== password) {
      errorObj.cpws = 'Password dose not match';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(resetPasswordAction(message, confirmPassword, props.history));
    setPassword('');
    setConfirmPassword('');

  };


  return (
    <>
      <div className="authincation h-100 p-meddle">
      <div className="container h-100">

        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Reset Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                        {errors.pws && <div className="text-danger fs-12">{errors.pws}</div>}
                      </div>
                      <div className="form-group">
                        <label className="mb-2">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                        {errors.cpws && <div className="text-danger fs-12">{errors.cpws}</div>}
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-login btn-block"
                        >
                          SUBMIT
                          {props.showLoading ?
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            : null}
                        </button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ResetPassword);
