import React, { useEffect, useRef } from "react";
// import './App.css';

import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import { Button, Modal, } from "react-bootstrap";

import {
  Position,
  Tooltip,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useSelector, useDispatch } from 'react-redux';

import { decode as base64_decode, encode as base64_encode } from 'base-64';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useState } from "react";

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useLocation, useHistory } from "react-router-dom"

const AWS = require('aws-sdk');
AWS.config.update({
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
});

const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

export default function PdfViewElibraryDam() {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const loacation = useLocation();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);
  const [previousScreenData, setPreviousScreenData] = useState(loacation.state);
  const [pdfUrl, setPdfUrl] = useState();
  const [showFirstPageView, setShowFirstPageView] = useState(0);
  const [isDocumentLoaded, setDocKeywords] = useState(false)
  const [registrationModal, setRegistrationModal] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(true)

  const firstTimeCallRef = useRef(true);

  useEffect(() => {
    if (firstTimeCallRef.current) {
      setIsFullScreen(true)
    }
    firstTimeCallRef.current = false

    const urlPdfPathValue = window.location.pathname.split('/');
    const modalOpenForRegistration = window.location.href.split('#');

    if (modalOpenForRegistration[1] == 8) {
      setRegistrationModal(true)
    }

    const endcodePdfValue = urlPdfPathValue[urlPdfPathValue.length - 1];
    let decodePdfUrl = base64_decode(endcodePdfValue);

    const showIntialPageNumber = decodePdfUrl.split("=")[1];
    getPdfContentFromAWSS3BucketPrivately(decodePdfUrl);
    setShowFirstPageView(showIntialPageNumber == undefined ? 0 : showIntialPageNumber - 1)

    const handleContextmenu = e => {
      e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  /* get s3 bucker pdf file */
  const getPdfContentFromAWSS3BucketPrivately = (url) => {
    setPdfUrl();

    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;

    let parts = url.split("/");
    const pdfFileName = parts[parts.length - 1];

    let pdfFileWithoutPageNumber = pdfFileName.split("#");
    let finalPdfFileWithoutPageNumber = pdfFileWithoutPageNumber[0];
    let fullPathPdf = `elibrary/${finalPdfFileWithoutPageNumber}`

    s3.getObject({
      Bucket: S3_BUCKET_NAME,
      Key: fullPathPdf,
      ResponseContentType: 'Uint8Array',
    }, (err, data) => {
      if (err) {
        console.log("@@Error : " + err);
      } else {
        setPdfUrl(data.Body);
      }
    });
  }

  /* view in full screen */
  const fullScreeTtoggleChange = () => {
    setIsFullScreen(!isFullScreen)
  }

  /* find link in pdf file */
  const openLinksPlugin = () => {

    const findLinks = (e) => {
      e.container.querySelectorAll('a:not([data-annotation-link-dest])').forEach((link) => {
        link.addEventListener('click', handleClick);
      });
    };

    const handleClick = (e) => {
      e.preventDefault();
      let href = (e.target).href;
      console.log("href", href);
      let domainName = process.env.REACT_APP_PDFURL;
      let midPath = "demo-e-library-pdf"
      if (href.indexOf("http") >= 0) {
        let parts = href.split("/");
        href = parts[parts.length - 1];
      }
      let finalURL = domainName + midPath + "/" + href;
      window.open(finalURL, '_self', 'noreferrer');
    };

    return {
      onAnnotationLayerRender: findLinks,
    }
  }

/* pdf file plugin */
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  let noteId = notes.length;

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={highlightContent}>
              <MessageIcon />
            </Button>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  /* pdf file plugin */
  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: 'none' }}>
          {props.textLayer.children}
        </div>
        {props.annotationLayer.children}
      </>
    );
  };

  /* navigation go back */
  const backToPrevious = () => {
    console.log("backToPrevious------", history )
    history.goBack()
  }

  const handleDocumentLoad = (e) => {
    setDocKeywords({
      isDocumentLoaded: true,
      // keywords
    });
  };

  return (
    <>
      <div className={`App ${isFullScreen ? "fullscreen" : ''}`}>
        <div className="pdf_fullScreen">
          <div className="top_back_container_pdf" onClick={() => backToPrevious()}>
            <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
            Back
          </div>
          <div className="pdf_right_side_container cursorPointer" onClick={() => fullScreeTtoggleChange()}>
            <div className="fullScreen">
              {!isFullScreen ? <i className="bi bi-arrows-fullscreen text_blue"></i> : <i className="bi bi-fullscreen-exit text_blue"></i>}

            </div>
            <div className="icon_description text_blue">
              {!isFullScreen ? "Full Screen" : "Normal Screen"}
            </div>
          </div>
        </div>
        {pdfUrl != undefined && pdfUrl != '' ?
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <div
              style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                padding: '4px',
              }}
            >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
            <div
              style={{ height: "700px", border: "1px solid rgba(250, 0, 250, 1)" }}
            >
              <Viewer
                // onDocumentLoad={handleDocumentLoad}
                initialPage={showFirstPageView}
                fileUrl={pdfUrl}
                renderPage={renderPage}
                plugins={[highlightPluginInstance, zoomPluginInstance]} //openLinksPluginInstance
              />
            </div>
          </Worker>
          : null}
      </div>
      <Modal className="fade" show={registrationModal}>
        <Modal.Header>
          <Modal.Title className="modal_title">user Decision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="readDisplay user_decision">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_right_container show_right">
            <Button
              variant="secondary text-center m-1"
              onClick={() => history.push({ pathname: "/dashboard" })}
            >
              Cancel
            </Button>
            <Button
              variant="warning text-center m-1"
              onClick={() => history.push({ pathname: "/page-register" })}
            >
              Registration
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
